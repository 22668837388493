import { uniq } from 'lodash-es';

import { ExperimentDto } from '@domain/models/experiment/ExperimentDto';
import { ExpDefinitionParams } from '@domain/models/experimentDetails/ExpDefinitionParams';
import { ExpObjectiveConfigParams } from '@domain/models/experimentDetails/ExpObjectiveConfigParams';
import { ExperimentObjectiveDto } from '@domain/models/experiment/ExperimentObjectiveDto';
import { ExpGroupsConfigParams } from '@domain/models/experimentDetails/ExpGroupsConfigParams';
import { UpdateExperimentDto } from '@domain/models/experiment/UpdateExperimentDto';
import { DevBasicInfoParams } from '@domain/models/experimentDetails/devPhase/DevBasicInfoParams';
import { DevTargetingParams } from '@domain/models/experimentDetails/devPhase/DevTargetingParams';
import { UpdateExperimentObjectiveDto } from '@domain/models/experiment/UpdateExperimentObjectiveDto';
import { DevPhaseParams } from '@domain/models/experimentDetails/devPhase/DevPhaseParams';
import { RegionMapper } from '@app/mappers/experiment/RegionMapper';
import { GenericConfigEntry } from '@domain/models/GenericConfigEntry';
import { DevObjFormConfig, DevObjInput } from '@domain/models/experimentDetails/devPhase/DevObjConfigParams';
import { ExperimentObjectiveSessionType } from '@domain/enums/ExperimentObjectiveSessionType';
import { ExperimentVariableWrapper } from '@domain/enums/ExperimentVariableWrapper';
import { RegionDto } from '@domain/models/RegionDto';

export class ExperimentDetailsMapper {
  static mapExpToDefinitionParams(experiment: ExperimentDto): ExpDefinitionParams {
    const params = ExpDefinitionParams.ofInitial();
    const [objective] = experiment.experimentObjectives;

    params.arpu = String(experiment.arpu);
    params.reportingArpu = experiment.reportingArpu.map((value) => ({ value: String(value) }));
    params.appVersions = objective.appVersions.map((value) => ({ value: String(value) }));
    params.checkPeriod = experiment.checkPeriod;
    params.automaticMode = experiment.automaticMode;
    params.importedSegments = experiment.importedSegments?.trim();

    return params;
  }

  static mapDefinitionParamsToUpdateDto(params: ExpDefinitionParams, experiment: ExperimentDto): UpdateExperimentDto {
    const dto = new UpdateExperimentDto();

    const additionalArpuValues = params.reportingArpu.splice(experiment.reportingArpu.length);

    dto.newTargetArpu = Number(params.arpu);
    dto.additionalArpuValues = additionalArpuValues.map(({ value }) => Number(value));
    dto.newCheckPeriod = params.checkPeriod;
    dto.newAutomaticMode = params.automaticMode;
    dto.newAppVersions = params.appVersions.map(({ value }) => value).filter(Boolean);
    dto.newImportedSegments = params.importedSegments;

    return dto;
  }

  static mapExpObjectiveToFormParams(objective?: ExperimentObjectiveDto): ExpObjectiveConfigParams {
    const params = ExpObjectiveConfigParams.ofInitial();

    if (!objective) {
      return params;
    }

    params.newUsers = objective.newUsers;
    params.sticky = objective.sticky;
    params.adjustableUsersAllocation = objective.adjustableUsersAllocation;
    params.usersAllocationPercent = objective.usersAllocationPercent;

    return params;
  }

  static mapObjectiveParamsToUpdateDto(
    params: ExpObjectiveConfigParams,
    objectiveId: number
  ): UpdateExperimentObjectiveDto {
    const dto = new UpdateExperimentObjectiveDto();

    dto.id = objectiveId;
    dto.newUsers = params.newUsers;
    dto.sticky = params.sticky;
    dto.adjustableUsersAllocation = params.adjustableUsersAllocation;
    dto.usersAllocationPercent = params.usersAllocationPercent;

    return dto;
  }

  static mapGroupsConfigToFormParams(objective?: ExperimentObjectiveDto): ExpGroupsConfigParams {
    const params = ExpGroupsConfigParams.ofInitial();

    if (!objective) {
      return params;
    }

    const { configs } = objective;

    configs.forEach((config) => {
      const { name, entry, active, description } = config;

      params.groups.push({ name, entry, checked: false, active, description });
    });

    return params;
  }

  static mapGroupsParamsToUpdateDto(params: ExpGroupsConfigParams): string[] {
    return params.groups.filter(({ checked }) => checked).map(({ name }) => name);
  }

  // Dev Phase

  static mapConfigsToObjForm(configs: GenericConfigEntry[]): DevObjFormConfig[] {
    const params = new Set<string>();

    // collect all possible params, each config might have different setup of them
    configs.forEach(({ entry }) => {
      Object.keys(entry).forEach((key) => params.add(key));
    });

    // for each config return proper form value
    return configs.map(({ name, description, entry, active }) => {
      const input = [] as DevObjInput[];

      params.forEach((param) => {
        // when entry doesn't have value for particular param - we add it as null(need it for form)
        const value = entry[param] ? entry[param].configValue.value : null;
        input.push({ key: param, value } as DevObjInput);
      });

      return { name, description, active, input };
    });
  }

  static mapConfigsToEntry(input: DevObjFormConfig[]): GenericConfigEntry[] {
    return input.map((item) => {
      const config = new GenericConfigEntry();

      const entry = item.input.reduce((acc, { key, value }) => {
        if (value === null) {
          return acc;
        }
        acc[key] = {
          type: ExperimentObjectiveSessionType.PLAIN,
          configValue: { type: ExperimentVariableWrapper.STRING, value }
        };
        return acc;
      }, {});

      config.name = item.name;
      config.description = item.description;
      config.active = item.active;
      config.entry = entry;

      return config;
    });
  }

  static mapExpToDevPhaseParams(experiment: ExperimentDto): DevPhaseParams {
    const formParams = DevPhaseParams.ofInitial();
    const expObjective = experiment.experimentObjectives[0];

    const basicInfo = new DevBasicInfoParams();

    basicInfo.experimentName = experiment.experimentName;
    basicInfo.arpu = String(experiment.arpu);
    basicInfo.description = expObjective.description;
    basicInfo.importedSegments = experiment.importedSegments;

    formParams.basicInfo = basicInfo;

    const config = this.mapConfigsToObjForm(expObjective.configs);

    // get all variables keys from objective entries to array
    const variables: string[] = [];
    expObjective.configs.forEach(({ entry }) => variables.push(...Object.keys(entry)));

    // pass only uniq keys to params
    const params = uniq(variables);

    formParams.objectiveConfig.config = config;
    formParams.objectiveConfig.params = params.map((value) => ({ value }));

    const targetingConfig = new DevTargetingParams();

    targetingConfig.regions = RegionMapper.createOptions(expObjective.regions);
    targetingConfig.primaryRegion = expObjective.primaryRegion.name;
    targetingConfig.regionsForInDev = RegionMapper.createOptions(expObjective.regionsForInDev);
    targetingConfig.appVersions = expObjective.appVersions.map((value) => ({ value }));

    formParams.targetingConfig = targetingConfig;

    return formParams;
  }

  static mapDevPhaseParamsToUpdateDtos(
    devPhaseParams: DevPhaseParams,
    experiment: ExperimentDto,
    defaultRegions: RegionDto[]
  ): { expUpdateParams: UpdateExperimentDto; objUpdateParams: UpdateExperimentObjectiveDto } {
    const expUpdateParams = new UpdateExperimentDto();
    const objUpdateParams = new UpdateExperimentObjectiveDto();

    const { basicInfo, targetingConfig, objectiveConfig } = devPhaseParams;

    const { experimentObjectives } = experiment;

    const objective = experimentObjectives[0];

    expUpdateParams.newName = basicInfo.experimentName;
    expUpdateParams.newTargetArpu = Number(basicInfo.arpu);
    expUpdateParams.newImportedSegments = basicInfo.importedSegments;

    objUpdateParams.configs = this.mapConfigsToEntry(objectiveConfig.config);

    objUpdateParams.description = basicInfo.description;
    objUpdateParams.id = objective.id;

    const [newPrimaryRegion] = RegionMapper.getRegionsByNames(defaultRegions, [targetingConfig.primaryRegion]);

    const newRegionsNames = targetingConfig.regions.map(({ value }) => value);
    const newRegions = RegionMapper.getRegionsByNames(defaultRegions, newRegionsNames).map(({ id }) => id);

    const newDevRegionsNames = targetingConfig.regionsForInDev.map(({ value }) => value);
    const newDevRegions = RegionMapper.getRegionsByNames(defaultRegions, newDevRegionsNames).map(({ id }) => id);

    objUpdateParams.primaryRegion = newPrimaryRegion.id;
    objUpdateParams.regions = newRegions;
    objUpdateParams.regionsForInDev = newDevRegions;
    objUpdateParams.appVersions = targetingConfig.appVersions.map(({ value }) => value);

    return { expUpdateParams, objUpdateParams };
  }
}
