import { IsEnum, IsString, IsBoolean, IsInt, Min, Max, MaxLength, ArrayMaxSize, IsArray } from 'class-validator';
import { Dto } from '@domain/models/Dto';
import { ExperimentPeriodicity } from '@domain/enums/ExperimentPeriodicity';

export class UpdateExperimentDto extends Dto {
  @IsInt()
  public newMinInstallDate: number;

  @IsBoolean()
  public newAutomaticMode: boolean;

  @IsString()
  @MaxLength(256)
  public newName: string;

  @IsInt({ each: true })
  @ArrayMaxSize(5)
  public additionalArpuValues: number[];

  @IsInt()
  @Min(1)
  @Max(31)
  public newTargetArpu: number;

  @IsEnum(ExperimentPeriodicity)
  public newCheckPeriod: ExperimentPeriodicity;

  @IsString({ each: true })
  @IsArray()
  public newAppVersions: string[];

  @IsString()
  public newImportedSegments: string;
}
